import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="pt-32 flex flex-col items-center">
      <h1 className="text-4xl pb-6">404: Not Found</h1>
      <p className=" text-lg pb-6">
        This page does not exist.{" "}
        <Link
          to="/"
          className="text-blue hover:text-underline hover:text-blue-dark pb-32"
        >
          Go back to the homepage.
        </Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
